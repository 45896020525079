<template>
  <div class="pixels-area">
    <div class="pixels-table">
      <b-table
          hover
          id="my-table"
          :fields="fields"
          show-empty
          small
          stacked="md"
          :items="pixels"
          :tbody-tr-class="rowClass"
          :per-page="perPage"
          :current-page="currentPage"
      >
        <template #cell(view)="row">
          <span>{{row.item.view ? 'Ativo' : 'Inativo'}}</span>
        </template>
        <template #cell(conversion)="row">
          <span>{{row.item.conversion ? 'Ativo' : 'Inativo'}}</span>
        </template>
        <template #cell(web)="row">
          <span>{{row.item.web ? 'Ativo' : 'Inativo'}}</span>
        </template>
        <template #cell(api)="row">
          <span>{{row.item.api ? 'Ativo' : 'Inativo'}}</span>
        </template>
        <template #cell(actions)="row">
          <b-button variant="info" size="sm" @click="info(row.item, row.index, $event.target)" class="mr-1">
            <font-awesome-icon :icon="['fa', 'edit']" />
          </b-button>
          <b-button variant="danger" size="sm" @click="deletePixel(row.item, row.index, $event.target)" class="mr-1">
            <font-awesome-icon :icon="['fas', 'trash']" />
          </b-button>
        </template>
      </b-table>

      <b-pagination
          v-if="pixels.length > 5"
          class="float-right"
          pills
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
      ></b-pagination>

      <b-form-select
          v-if="pixels.length > 5"
          id="input-3"
          class="select-perpage"
          v-model="perPage"
          :options="types"
          required
      ></b-form-select>
      <!-- <p class="mt-3">Current Page: {{ currentPage }}</p> -->
    </div>
    <!-- Info modal -->
    <b-modal :id="infoModal.id" :title="infoModal.title" ok-only @hide="resetInfoModal">
      <pre>{{ infoModal.content }}</pre>
    </b-modal>

    <div id="load" class="sk-cube-grid" v-if="loading">
      <div class="sk-cube sk-cube1"></div>
      <div class="sk-cube sk-cube2"></div>
      <div class="sk-cube sk-cube3"></div>
      <div class="sk-cube sk-cube4"></div>
      <div class="sk-cube sk-cube5"></div>
      <div class="sk-cube sk-cube6"></div>
      <div class="sk-cube sk-cube7"></div>
      <div class="sk-cube sk-cube8"></div>
      <div class="sk-cube sk-cube9"></div>
    </div>
  </div>
</template>

<script>
import Snackbar from "../../../node_modules/node-snackbar";
import "../../../node_modules/node-snackbar/dist/snackbar.min.css";

export default {
  name:'PixelsTable',
  data(){
    return {
      perPage: 10,
      loading: false,
      currentPage: 1,
      types : [
        { value: "5", text: "5" },
        { value: "10", text: "10" },
        { value: "20", text: "20" },
        { value: "30", text: "30" },
        { value: "50", text: "50" },
      ],
      fields: [
        // { key: 'id', label: 'ID'},
        { key: 'pixel_id', label: 'Pixel ID'},
        { key: 'type', label: 'Tipo'},
        { key: 'title', label: 'Titulo'},
        { key: 'view', label: 'Visualização'},
        { key: 'conversion', label: 'Conversão'},
        { key: 'web', label: 'Envios Web'},
        { key: 'api', label: 'Api de conversão'},
        { key: 'actions', label: 'Actions' }
      ],
      pixels:[],
      teste:false,
      rowClass:'row-table-body',
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      }
    }
  },
  computed: {
    rows() {
      return this.pixels.length
    }
  },
  async mounted(){
    await this.handlePixel();

    this.$root.$on('updateTable', async  data => {
      this.pixels = [];
      await this.handlePixel();
    });

    this.$root.$on('removeItem', async  data => {
      this.handleDeletePixel(item.id)
    });
  },
  methods:{
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', 'modal-1', button)
      this.$root.$emit('modaledit', item )
    },
    deletePixel(item, index, button) {
      console.log({item, index, button})
      let t = this
      Snackbar.show({
        pos: 'top-left',
        text: 'Remover o pixel e todas suas ligações ?',
        width: '475px',
        actionText: 'Confirmar',
        actionTextColor: '#ff0000',
        onActionClick: function(element) {
          t.handleDeletePixel(item.id);

        }
      });
    },
    loadOn() {
      this.loading = true;
    },
    loadOff() {
      this.loading = false;
    },
    getPixels(params = null){

      let myHeaders = new Headers();
      myHeaders.append("Authorization", Cookies.get('access_token'));
      myHeaders.append("Content-Type", "application/json");

      let requestOptions =  {
        method : 'GET',
        headers : myHeaders,
        redirect : 'follow'
      };

      let url = `${process.env.VUE_APP_API_HOST}/pixel-post/`
      if(params){
        url = `${process.env.VUE_APP_API_HOST}/pixel-post/?page=${params}`
      }

      return new Promise(resolve =>  {
        fetch(`${process.env.VUE_APP_API_HOST}/pixel-post/`, requestOptions)
            .then(response => {
              resolve(response.json());
            })
            .catch(error => {
              resolve(false);
              console.log('error', error);
            });
      });
    },
    handleDeletePixel(id){
      this.loadOn();
      let myHeaders = new Headers();
      myHeaders.append("Authorization", Cookies.get('access_token'));
      myHeaders.append("Content-Type", "application/json");

      let requestOptions =  {
        method : 'DELETE',
        headers : myHeaders,
        redirect : 'follow'
      };

      return new Promise(resolve => {
        fetch(`${process.env.VUE_APP_API_HOST}/pixel-post/${id}`, requestOptions)
            .then(response => {
              resolve(response.json());
            }).then(()=>{
          this.loadOff();
          this.$root.$emit('updateTable');
          Snackbar.show({
            pos: 'top-left',
            text: 'Pixel removido com sucesso',
            width: '475px',
            showAction: false
          });
        })
            .catch(error => {
              resolve(false);
              console.log('error', error);
            });
      });
    },
    async handlePixel(){
      this.loadOn();
      let pixel = await this.getPixels()
      console.log(pixel )
      this.pixels = pixel;
      setTimeout(()=>{
        this.loadOff();
      },1500)
    }


  }
}
</script>

<style>

.pixels-area{
  padding: 0% 5% 1% 10%;
}
.select-perpage{
  width: 6% !important;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #00e4a0;
  border-color: #00e4a0;
}

.page-link{
  color: black ;
}

.page-link:hover {
  z-index: 2;
  color: black !important;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #00e4a0;
}

.table thead th {
  padding: 15px;
}

.table-sm th, .table-sm td {
  padding: 1rem;
}

.sk-cube-grid {
  width: 80px;
  height: 80px;
  position: absolute;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
  z-index: 999999999999999999999999999999999999999;
  display: block;
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: #333;
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}
.sk-cube-grid .sk-cube1 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.sk-cube-grid .sk-cube2 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.sk-cube-grid .sk-cube3 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.sk-cube-grid .sk-cube4 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.sk-cube-grid .sk-cube5 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.sk-cube-grid .sk-cube6 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.sk-cube-grid .sk-cube7 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.sk-cube-grid .sk-cube8 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.sk-cube-grid .sk-cube9 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

@keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}
</style>