<template>
    <div class="header-base d-flex align-items-center justify-content-between">
        <div class="pixel-type d-flex align-items-center justify-content-around ">
              <div class="d-flex align-items-center">
                <div class="dot"></div>
                <h1 class="m-0">Pixel</h1>
              </div>
        </div>
          <b-button class="btn-new" @click="openModalCreate">Novo Pixel</b-button>
    </div>
</template>

<script>
import ModalAddPixel from './ModalAddPixel'

export default {
  name: "HeaderBase",
 components: {
    ModalAddPixel,
  },
  methods:{
    openModalCreate(event){
      this.$root.$emit('openModalCreate', event )
    }
  }

};
</script>

<style scoped>

.btn-new{
  background: #00E4A0;
  border: none;
  font-weight: 500;
}
.iconePixel{
  width: 20%;
  height: 25%;
}
.dot {
  border-radius: 5px;
  width: 20px;
  height: 7px;
  background-color: rgb(2, 251, 177);
  margin-right: 15.2px;
}
.header-base{
  /* border: 1px solid red; */
  justify-content: flex-end;
  padding: 3% 5% 4% 10%;
}
.text-secondary{
  font-size: 14px;
  line-height: 17px;
}

.total-integration{
  margin-top:40px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #81858E;
}
.content-info {
  margin-top: 41px;
}
.content-icon {
  width: 40px;
  height: 40px;
  background: rgba(237, 237, 240, 0.5);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
img {
  width: 13px;
  height: 14.33px;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px !important;
  line-height: 17px;
  color: #000916;
}

.btn-integration,
.btn-open-modal-new-pixel {
  height: 50px;
  border-radius: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  border: 0px;
}
.btn-open-modal-new-pixel:active {
  border: 0px;
}
.btn-integration {
  width: 216px;
  background-color: var(--background-color-white-light);
  margin-right: 25px;
}

.btn-open-modal-new-pixel {
  width: 209px;
  color: #ffffff;
  border: 0px;
  background-color: #00e4a0;
}

.btn-open-modal-new-pixel:hover {
  color: #ffffff;
  border: 0px;
}
</style>