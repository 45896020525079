<template>
  <div>
    <Slidebar/>
    <HeaderBase/>
    <Pixel/>
    <b-modal id="modal-1" class="mt-5" hide-footer>
      <template #modal-title>
        {{ modalTitle }}
      </template>
      <b-form @submit="handleSubmit" @reset="resetModal">

        <b-form-group id="input-group-3" label="Selecione o tipo do pixel" label-for="input-3">
          <b-form-select
              id="input-3"
              v-model="form.type"
              :options="types"
              required
              :disabled="true"
          ></b-form-select>
        </b-form-group>

        <b-form-group
            label="Titulo"
            label-for="titulo"
            invalid-feedback="Title is required"
        >
          <b-form-input
              id="titulo"
              v-model="form.title"
              required
          ></b-form-input>
        </b-form-group>




        <b-form-group
            label="Pixel Id"
            label-for="name-input"
            invalid-feedback="Name is required"
        >
          <b-form-input
              id="name-input"
              v-model="form.pixel"
              required
              :disabled="disabled"
          ></b-form-input>
        </b-form-group>


        <b-form-group
            label="Teste Pixel"
            label-for="Teste Pixel"
        >
          <b-form-input
              id="test_pixel"
              v-model="form.testEventCodePixel"
          ></b-form-input>
          <small>No final da url da página adicione <strong>?test_event=teste</strong>, para que o facebook possa localizar o evento cadastrado. </small>
        </b-form-group>




        <b-form-group v-slot="{ ariaDescribedby }">

          <b-form-checkbox v-model="form.view" :aria-describedby="ariaDescribedby" value="1" unchecked-value="0">Eventos
            de visualização
          </b-form-checkbox>

        </b-form-group>
        <b-form-group v-slot="{ ariaDescribedby }">

          <b-form-checkbox v-model="form.conversion" :aria-describedby="ariaDescribedby" value="1" unchecked-value="0">
            Eventos de Conversão
          </b-form-checkbox>

        </b-form-group>
        <b-form-group v-slot="{ ariaDescribedby }">

          <b-form-checkbox v-model="form.web" :aria-describedby="ariaDescribedby" value="1" unchecked-value="0">
            Navegador
          </b-form-checkbox>
          <small>Dados serão salvos direto do navegador do usuário</small>

        </b-form-group>
        <b-form-group id="input-group-5" v-slot="{ ariaDescribedby }">

          <b-form-checkbox
              v-if="form.type == 'FACEBOOK'"
              v-model="form.api"
              :aria-describedby="ariaDescribedby"
              value="1"
              unchecked-value="0"
          >Api de conversão
          </b-form-checkbox>
          <small v-if="form.type == 'FACEBOOK'">Dados serão enviados para a API de conversão</small>

        </b-form-group>


        <b-form-group v-if="form.api != '0' "
                      label="Token api de conversão"
                      label-for="token-api"
                      invalid-feedback="O token é requerido quando Api for 1"
        >
          <b-form-input
              id="name-input"
              v-model="form.token"
              required
          ></b-form-input>
        </b-form-group>

        <b-button type="submit" class="float-right" variant="primary">Salvar</b-button>
        <b-button type="reset" class="float-right mr-3" variant="danger">Cancelar</b-button>
      </b-form>
    </b-modal>

    <div id="load" class="sk-cube-grid" v-if="loading">
      <div class="sk-cube sk-cube1"></div>
      <div class="sk-cube sk-cube2"></div>
      <div class="sk-cube sk-cube3"></div>
      <div class="sk-cube sk-cube4"></div>
      <div class="sk-cube sk-cube5"></div>
      <div class="sk-cube sk-cube6"></div>
      <div class="sk-cube sk-cube7"></div>
      <div class="sk-cube sk-cube8"></div>
      <div class="sk-cube sk-cube9"></div>
    </div>
  </div>
</template>

<script>
import Snackbar from "../../../node_modules/node-snackbar";
import "../../../node_modules/node-snackbar/dist/snackbar.min.css";
import Slidebar from './Slidebar.vue';
import HeaderBase from './HeaderBase.vue';
import Pixel from './Pixel.vue';
import {ModelSelect} from 'vue-search-select';
import Cookies from "js-cookie";

export default {
  name: 'MainPixel',
  components: {
    HeaderBase,
    Slidebar,
    Pixel,
    ModelSelect
  },
  data() {
    return {
      disabled: false,
      modalTitle: "",
      options: [],
      item: {
        value: '',
        text: ''
      },
      post: '',
      form: {
        id: false,
        pixel: "",
        testEventCodePixel: "",
        type: "",
        api: false,
        web: false,
        view: false,
        conversion: false,
        token: "",
        post_id: "",
        title: "",
      },
      loading: false,
      types: [
        {value: "FACEBOOK", text: "Facebook"},
        // { value: "GOOGLEADWORDS", text: "Google AdWords" },
        // { value: "GOOGLETAGMANAGER", text: "Google Tag Manager" },
        // { value: "GOOGLEANALYTICS", text: "Google Analytics" },
        // { value: "TIKTOK", text: "TikTok" },
      ]
    }
  },
  async mounted() {

    this.$root.$on('modaledit', data => {

      console.log(data);

      this.form.pixel = data.pixel_id;
      this.form.token = data.token ?? "";
      this.form.type = data.type;
      this.form.title = data.title;
      this.form.testEventCodePixel = data.test_event_code_pixel;
      this.form.api = data.api ? 1 : 0;
      this.form.web = data.web ? 1 : 0;
      this.form.view = data.view ? 1 : 0;
      this.form.id = data.id;
      this.form.conversion = data.conversion ? 1 : 0;
      this.disabled = true;
      this.modalTitle = "Editar Pixel";
    });

    this.$root.$on('openModalCreate', data => {
      this.$root.$emit('bv::show::modal', 'modal-1', data)
      this.form.pixel = ""
      this.form.token = ""
      this.form.type = "FACEBOOK"
      this.form.testEventCodePixel = "";
      this.form.title = ""
      this.form.api = 0
      this.form.web = 0
      this.form.id = 0
      this.form.view = 0
      this.form.conversion = 0
      this.disabled = false;
      this.modalTitle = "Novo Pixel";
    });

  },
  methods: {
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },

    resetModal() {
      this.form.pixel = "";
      this.form.id = 0;
      this.form.token = "";
      this.form.testEventCodePixel = "";
      this.form.type = "";
      this.form.title = "";
      this.form.api = 0;
      this.form.web = 0;
      this.form.view = 0;
      this.form.conversion = 0;
      this.$root.$emit('bv::hide::modal', 'modal-1');
    },

    async handleSubmit(event) {
      event.preventDefault()
      console.log(this.form);
      console.log(event)

      if (this.form.id != 0) {

        await this.updatePixel(this.form, this.form.id)
        this.$nextTick(() => {
          this.$bvModal.hide('modal-prevent-closing')
        })
      } else {
        await this.createPixel(this.form)
        this.$nextTick(() => {
          this.$bvModal.hide('modal-prevent-closing')
        })

      }

    },
    loadOn() {
      this.loading = true;
    },
    loadOff() {
      this.loading = false;
    },
    async createPixel(form) {
      this.loadOn()
      let store = this.$store;

      let raw = JSON.stringify({
        "pixel_id": form.pixel,
        "blog_id": store.getters.blog.blog_id,
        "type": form.type,
        "test_event_code_pixel": form.testEventCodePixel,
        "title": form.title,
        "view": form.view,
        "conversion": form.conversion,
        "web": form.web,
        "api": form.api,
        "token": form.token
      });


      let myHeaders = new Headers();
      myHeaders.append("Authorization", Cookies.get('access_token'));
      myHeaders.append("Content-Type", "application/json");

      let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
        body: raw
      };


      return new Promise(resolve => {
        fetch(`${process.env.VUE_APP_API_HOST}/pixel-post`, requestOptions)
            .then(response => response.json())
            .then((data) => {
              // this.$root.$emit('updateTable');
              console.log(data)
              if (data.error) {
                Snackbar.show({
                  pos: 'bottom-left',
                  text: data.error,
                  width: '475px',
                  actionText: 'Entendido!',
                });
                resolve(true)
              } else {
                this.loadOff()
                Snackbar.show({
                  pos: 'bottom-left',
                  text: 'Pixel criado com sucesso',
                  width: '475px',
                  showAction: false
                });

                this.resetModal();
                this.$root.$emit('updateTable');

              }

            })

            .catch(error => {
              this.loadOn()
              resolve(false);
              console.log('error', error);
            });
      });

    },
    async updatePixel(form, id) {
      this.loadOn()
      let store = this.$store;

      let raw = JSON.stringify({
        "pixel_id": form.pixel,
        "blog_id": store.getters.blog.blog_id,
        "test_event_code_pixel": form.testEventCodePixel,
        "type": form.type,
        "view": form.view,
        "title": form.title,
        "conversion": form.conversion,
        "web": form.web,
        "api": form.api,
        "token": form.token
      });


      // console.log({raw})
      let myHeaders = new Headers();
      myHeaders.append("Authorization", Cookies.get('access_token'));
      myHeaders.append("Content-Type", "application/json");

      let requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        redirect: 'follow',
        body: raw
      };


      return new Promise(resolve => {
        fetch(`${process.env.VUE_APP_API_HOST}/pixel-post/${id}`, requestOptions)
            .then(response => response.json())
            .then((data) => {
              // this.$root.$emit('updateTable');
              console.log(data)
              if (data.error) {
                Snackbar.show({
                  pos: 'top-left',
                  text: data.error,
                  width: '475px',
                  actionText: 'Entendido!',
                });
                resolve(true)
              } else {
                this.loadOff()
                Snackbar.show({
                  pos: 'top-left',
                  text: 'Pixel Atualizado com sucesso',
                  width: '475px',
                  showAction: false
                });

                this.resetModal();
                this.$root.$emit('updateTable');
              }

            })
            .catch(error => {
              this.loadOn()
              resolve(false);
              console.log('error', error);
            });
      });

    }

  }
}
</script>

<style>
#modal-1 {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

#modal-1 .modal-dialog {
  width: 50%;
}

.info-checkbox .custom-control {
  display: flex !important;
}

.form-checkbox div:first-of-type {
  display: flex !important;
}

.sk-cube-grid {
  width: 80px;
  height: 80px;
  position: absolute;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
  z-index: 999999999999999999999999999999999999999;
  display: block;
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: #333;
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}

.sk-cube-grid .sk-cube1 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube2 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube3 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.sk-cube-grid .sk-cube4 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube5 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube6 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube7 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.sk-cube-grid .sk-cube8 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube9 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

@keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}
</style>