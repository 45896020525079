<template>
  <div class="pixel">
    <PixelTable/>
  </div>
</template>

<script>
import PixelTable from './PixelTable.vue';

export default {
    name:'Pixel',
    components:{
        PixelTable
    },
    data(){
        return {
            itemPixels: {
                pixel_id: "",
                type: "FACEBOOK",
            },
            typesPixels: [
            // { value: null, text: "Selecione o Tipo", disabled: true },
            { value: "FACEBOOK", text: "Facebook" },
            { value: "GOOGLEADWORDS", text: "Google AdWords" },
            { value: "GOOGLETAGMANAGER", text: "Google Tag Manager" },
            { value: "GOOGLEANALYTICS", text: "Google Analytics" },
            { value: "TIKTOK", text: "TikTok" },
        ],
        }
    },
    methods: {
        changePixelType(e) {
        console.log(e)
        },
        iconPixel(type) {
        return require(`@/assets/img/${type.toLowerCase()}_icone.png`);
        },
    },
}
</script>

<style>

/* .pixel{
    padding: 0% 5% 5% 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
} */

.pixel-id{
    padding: 0% 5% 5% 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pixel .form-group{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 30%;
    margin-bottom: 0px !important;
}

</style>